var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-payment-auth" },
    [
      _c("CategoryTitle", { attrs: { category: _vm.category } }),
      _c(
        "div",
        [
          _c("PaymentTypeList", {
            attrs: {
              modality: "addAuth",
              options: _vm.paymentTypeListOptions,
              filteredPaymentTypeIds: _vm.config.addAuthPaymentTypeIds
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }